import Client from "../components/Clients/Clients";
import Footer from "../components/Footer";
import { useEffect } from "react";
export default function Cliants() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ background: "#121316" }}>
      <Client />
      <div className="sections" style={{ paddingTop: 0 }}>
        <Footer />
      </div>
    </div>
  );
}
