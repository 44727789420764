import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import arrow from "../../assets/arrow.svg";
import db from "../../database/firebase";
import "./portfolio.css";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

function yalla(el) {
  document.getElementById(el).style.opacity = "1";
}

function unyalla(el) {
  document.getElementById(el).style.opacity = "0";
}

export default function Portfolio() {
  const history = useHistory();
  const classes = useStyles();
  const [post, set] = useState([]);
  useEffect(() => {
    db.firestore()
      .collection("projects")
      .orderBy("name", "asc")
      .limit(4)
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data(), id: r.id };
        });
        set([...all]);
      });
  }, []);

  return (
    <div className="sections" id="projects">
      <div data-aos="fade-right" data-aos-duration="1500">
        <div className="the-line" />
        <div className="welcome-techeye" id="about-about">
          PORTFOLIO
        </div>
        <br />
        <span className="portfolio-head-t" id="services-desc">
          Our Latest Projects<span className="span_dot"></span>
        </span>
        <div
          className="portfolio-p contentakan"
          id="services-con"
          style={{ width: 570 }}
        >
          We Help Amazing Companies  Build Awesome Products.
          <br />
          Thanks to all our customers who trust us and walk with us on this path
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="seeAll no_select"
      >
        <Link to="/projects-all">
          SEE ALL PROJECTS <img alt="arrow" src={arrow} />
        </Link>
      </div>
      <div id="projects-slides">
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center">
              {post.map((el, i) => (
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  key={el.name}
                  className="project-el"
                >
                  <div style={{ display: "relative" }}>
                    <img alt={el.name} src={el.img} />
                    <div
                      onClick={() => {
                        history.push(`/projects/${el.id}`);
                      }}
                      onMouseEnter={() => yalla(`project-shadow-${i}`)}
                      onMouseLeave={() => unyalla(`project-shadow-${i}`)}
                      className="project-shadow"
                      id={`project-shadow-${i}`}
                    >
                      <span>{el.name}</span>
                    </div>
                  </div>
                  <h3 style={{ margin: "0px 0px 5px 0px", color: "white" }}>
                    {el.name}
                  </h3>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
