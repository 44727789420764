import React, { useState } from "react";
import "./Nav.css";
import logo from "../../assets/tech.svg";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

export default function Nav() {
  const history = useHistory();
  const [classing, set] = useState("");
  return (
    <nav>
      <img
        alt="logo"
        src={logo}
        onClick={() => {
          window.scrollTo(0, 0);
          if (window.location.pathname) {
            history.push("/");
          }
        }}
        className="no_select"
        style={{ width: 60, transformOrigin: "center", cursor: "pointer" }}
      />
      <div id="nav-part1">
        <NavLink
          onClick={() => {
            const el = document.getElementById("services");
            if (el) window.scrollTo(0, el.offsetTop);
          }}
          className="nav1"
          activeClassName="active"
          to="/services-detail"
          exact={true}
        >
          Services
        </NavLink>
        <NavLink
          onClick={() => {
            const el = document.getElementById("clients");
            if (el) window.scrollTo(0, el.offsetTop);
          }}
          className="nav1"
          activeClassName="active"
          to="/clients"
          exact={true}
        >
          Clients
        </NavLink>
        <NavLink
          onClick={() => {
            const el = document.getElementById("form");
            if (el) window.scrollTo(0, el.offsetTop);
          }}
          className="nav1"
          activeClassName="active"
          to="/contact"
          exact={true}
        >
          Contact
        </NavLink>
      </div>
      <div id="Navh-second" className="no_select">
        <div
          onClick={() => window.open("tel:+9647504515480", "_self")}
          id="callnumber"
          onMouseEnter={() => set("callnumberopen")}
          onMouseLeave={() => set("")}
          className={classing}
        >
          <i className="fas fa-phone-alt"> </i>
          <span>+964 750 451 5480</span>
        </div>
        <button
          id="get2"
          onClick={() => {
            const el = document.getElementById("form");
            if (el) window.scrollTo(0, el.offsetTop);
          }}
        >
          <NavLink style={{ color: "white" }} to="/contact">
            Get a Quote
          </NavLink>
        </button>
      </div>
    </nav>
  );
}
