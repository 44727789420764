import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import background from "../../assets/choose-us.svg";
import db from "../../database/firebase";
import CountUp from "react-countup";

import "./choose.css";

export default class Choose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        { title: "Satisfied Clients", number: 0 },
        { title: "Team Members", number: 0 },
        { title: "Freelancers", number: 0 },
        { title: "Projects Completed", number: 0 },
      ],
    };
  }

  componentDidMount() {
    db.firestore()
      .collection("clients")
      .orderBy("name", "asc")
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data() };
        });
        this.setState({ projects: all });
      });
  }
  render() {
    const N0 = () => {
      const ref = React.useRef();
      const isVisible = useOnScreen(ref);

      return (
        <span ref={ref}>
          {isVisible ? (
            <CountUp
              start={0}
              duration={this.state.cct}
              end={this.state.projects[0].number}
            />
          ) : (
            0
          )}
          <span className="choose-plus">+</span>
        </span>
      );
    };
    const N1 = () => {
      const ref = React.useRef();
      const isVisible = useOnScreen(ref);

      return (
        <span ref={ref}>
          {isVisible ? (
            <CountUp
              start={0}
              duration={this.state.cct}
              end={this.state.projects[1].number}
            />
          ) : (
            0
          )}
          <span className="choose-plus">+</span>
        </span>
      );
    };
    const N2 = () => {
      const ref = React.useRef();
      const isVisible = useOnScreen(ref);

      return (
        <span ref={ref}>
          {isVisible ? (
            <CountUp
              start={0}
              duration={this.state.cct}
              end={this.state.projects[2].number}
            />
          ) : (
            0
          )}
          <span className="choose-plus">+</span>
        </span>
      );
    };
    const N3 = () => {
      const ref = React.useRef();
      const isVisible = useOnScreen(ref);

      return (
        <span ref={ref}>
          {isVisible ? (
            <CountUp
              start={0}
              duration={this.state.cct}
              end={this.state.projects[3].number}
            />
          ) : (
            0
          )}
          <span className="choose-plus">+</span>
        </span>
      );
    };
    return (
      <div
        className="sections"
        id="choose"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container className="kflex">
          <Grid
            item
            md={12}
            lg={6}
            style={{
              justifyContent: "center",
              width: "100%",
              alignContent: "center",
            }}
          >
            <div data-aos="fade-right" data-aos-duration="1500">
              <div className="the-line" />
              <div className="choosel welcome-techeye" id="about-about">
                A FEW WORDS
              </div>
              <br />
              <span className="chooseu" id="services-desc">
                Why Choose us<span className="span_dot"></span>
              </span>
              <div
                className="choosep contentakan"
                id="services-con"
                style={{ width: 480 }}
              >
                We are known for our reliability and the amount of effort we put
                in our work to make them better.You may be amazed by what we can
                do. We offer many services which you can find below.
              </div>
            </div>
          </Grid>

          <Grid
            item
            md={12}
            lg={6}
            className="kflex"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              className="kflex services-one"
              style={{
                justifyContent: "center",
              }}
            >
              <div
                className="choose-boxes"
                data-aos="fade-down-right"
                data-aos-duration="1000"
              >
                <N0 />
                <span className="choose-card-desc">
                  {this.state.projects[0].title}
                </span>
              </div>
              <div
                className="choose-boxes"
                data-aos="fade-down-left"
                data-aos-duration="1000"
              >
                <N1 />
                <span className="choose-card-desc">
                  {this.state.projects[1].title}
                </span>
              </div>
            </div>
            <div
              className="kflex services-two"
              style={{
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                className="choose-boxes"
                data-aos="fade-up-right"
                data-aos-duration="1000"
              >
                <N2 />
                <span className="choose-card-desc">
                  {this.state.projects[2].title}
                </span>
              </div>
              <div
                className="choose-boxes"
                data-aos="fade-up-left"
                data-aos-duration="1000"
              >
                <N3 />
                <span className="choose-card-desc">
                  {this.state.projects[3].title}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  React.useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}
