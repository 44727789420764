import { Layout, List, Avatar, Popover, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import "./dasboard.css";
import techeye from "../../assets/tech.svg";
import { Route, Switch, Link } from "react-router-dom";
import db from "../../database/firebase";

import routes from "./routes";

const { Header, Sider, Content } = Layout;
export default class Dashboard extends Component {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  Logout = () => db.auth().signOut();

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <img id="techeyelogod" alt="techeye" src={techeye} />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
          >
            {routes.map((el) => (
              <Menu.Item key={el.path} icon={el.logo}>
                <Link to={el.path}> {el.name}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              paddingRight: 25,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
            <Popover
              content={
                <List>
                  <List.Item>
                    <a href="# " onClick={this.Logout}>
                      Log out
                    </a>
                  </List.Item>
                </List>
              }
              trigger="hover"
              arrowPointAtCenter
              placement="bottomLeft"
            >
              <Avatar
                src={`https://www.pngfind.com/pngs/m/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png`}
              />
            </Popover>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <Switch>
              {routes.map((el) => (
                <Route
                  key={el.path}
                  path={el.path}
                  exact={el.exact}
                  component={el.component}
                />
              ))}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
