import React, { useEffect, useState } from "react";
import "./services.css";
import { useParams } from "react-router-dom";
import db from "../../database/firebase.js";

export default function Services() {
  let { id } = useParams();
  const [p, set] = useState({});
  useEffect(() => {
    db.firestore()
      .collection("products")
      .doc(id)
      .get()
      .then((r) => {
        set(r.data());
        console.log(r.data());
      });
    // test

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ paddingTop: 120 }} className="sections">
      <div className="the-line" />
      <div className="welcome-techeye" id="about-about">
        OUR PRODUCTS
      </div>
      <br />
      <div className="serv1-h">
        <span className="portfolio-head-t" id="services-desc">
          {p.name}
          <span className="span_dot"></span>
        </span>
      </div>
      <iframe
        title={p.name}
        src={p.vid}
        alt="mobile"
        className="serv1-mobile"
      />

      <div className="serv1-detail">{p.detail}</div>
    </div>
  );
}
