import React from "react";
import image from "../../assets/social.svg";
import Grid from "@material-ui/core/Grid";
import "./socail.css";
export default function Social() {
  return (
    <div
      className="sections"
      id="social"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div data-aos="fade-right" data-aos-duration="1500">
        <div className="social-tt-ll the-line" />
        <div className="social-tp-ll welcome-techeye" id="about-about">
          SOCIAL MEDIA MANAGEMENT
        </div>
        <br />
        <span className="social-manage" id="services-desc">
          We Manage Your Media
          <span className="span_dot"></span>
        </span>
        <div
          className="social-manage-pp social-tt tt-desc contentakan"
          style={{ width: 695 }}
        >
          You may be surprised by our social media management packages, we take
          care of the following accounts and more.
        </div>
      </div>
      <Grid container className="kflex">
        <Grid
          item
          md={12}
          className="kflex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            className="kflex services-one"
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <a
              data-aos="fade-down-right"
              data-aos-duration="1200"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/techeyeco/"
              className="choose-boxes"
            >
              <i className="fab fa-instagram"></i>
              <span className="choose-card-desc">Instagram</span>
            </a>
            <a
              data-aos="fade-down-left"
              data-aos-duration="1200"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/techeye.org"
              className="choose-boxes"
            >
              <i className="fab fa-facebook-f"></i>
              <span className="choose-card-desc">Facebook</span>
            </a>
          </div>
          <div
            className="kflex services-two"
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <a
              data-aos="fade-up-left"
              data-aos-duration="1200"
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/techeye-co/mycompany/"
              className="choose-boxes"
            >
              <i className="fab fa-linkedin-in"></i>
              <span className="choose-card-desc">Linkedin</span>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
