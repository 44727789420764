import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import db from "../../database/firebase";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../Footer";
import "./form.css";
import { Modal } from "antd";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

export default function Form() {
  function def() {
    enset({ error: false, t: "" });
    eeset({ error: false, t: "" });
    emset({ error: false, t: "" });
  }

  function reset() {
    def();
    nset("");
    eset("");
    mset("");
  }
  function ch(funi) {
    def();
    return funi;
  }

  function countDown() {
    const modal = Modal.success({
      title: "Tech-eye",
      content: `Thank you for conatcing us, You will hear from us soon.`,
      centered: true,
      mask: true,
    });
    setTimeout(() => {
      modal.destroy();
    }, 50000);
  }

  function submit(e) {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      if (name === "") {
        enset({ error: true, t: "please enter name." });
      }
      if (email === "") {
        eeset({ error: true, t: "please enter email." });
      }
      if (message === "") {
        emset({ error: true, t: "please enter message." });
      }
      return;
    }
    db.firestore()
      .collection("contact")
      .add({
        name: name,
        email: email,
        message: message,
      })
      .then(() => {
        reset();
        countDown();
      });
  }

  const [name, nset] = useState("");
  const [email, eset] = useState("");
  const [message, mset] = useState("");
  const [ename, enset] = useState({ error: false, t: "" });
  const [eemail, eeset] = useState({ error: false, t: "" });
  const [emessage, emset] = useState({ error: false, t: "" });
  let image =
    "https://bl6pap003files.storage.live.com/y4mFYNfG0f7IJx04AqSqNpkDy7QxHo5o91w7vcb3OjQsmBG0NVKK5Id3U9YHnSDGpRVqwEhvmwArylWaNjj7Nzjt1Tu16zeplhnYs4XXNtTWhc-9gRVneg7uBBAuhEawj0tIR5ZOaAH1GXev3jk_uhycG-nnLteCa_caXqCeiqQpm0zNbi8fhrqwaCQdgejVNcv?width=3237&height=1950&cropmode=none";
  return (
    <div
      id="form"
      className="sections form-modal"
      style={{
        background: `url(${image})`,
        paddingBottom: 10,
        backgroundSize: "cover",
        paddingTop: 50,
      }}
    >
      <div data-aos="fade-right" data-aos-duration="1500">
        <div className="the-line" />
        <div className="welcome-techeye form_shadow_text" id="about-about">
          LET'S GET STARTED
        </div>
        <br />
        <span className="form-big-head" id="services-desc">
          Let's Have A <br />
          Conversation<span className="span_dot"></span>
        </span>
        <div
          className="form-talks-k tt-desc form_shadow_text contentakan"
          style={{ width: 450 }}
        >
          We always try to implement our creative ideas at the highest level.
          Tell us about your project and we will make it work.
        </div>
      </div>
      <div className="form-container-k">
        <form autoComplete="off" onSubmit={submit}>
          <Grid container spacing={2}>
            <Grid style={{ width: "100%" }} item sm={12} md={6}>
              <CssTextField
                error={ename.error}
                label="Name"
                variant="outlined"
                id="white-input"
                helperText={ename.t}
                value={name}
                fullWidth={true}
                onChange={(e) => ch(nset(e.target.value))}
              />
            </Grid>
            <Grid style={{ width: "100%" }} item sm={12} md={6}>
              <CssTextField
                label="Email"
                value={email}
                helperText={eemail.t}
                error={eemail.error}
                fullWidth={true}
                variant="outlined"
                id="white-input1"
                onChange={(e) => ch(eset(e.target.value))}
              />
            </Grid>
            <Grid style={{ width: "100%" }} item sm={12} md={12}>
              <CssTextField
                error={emessage.error}
                label="Message"
                value={message}
                multiline={true}
                rows={5}
                rowsMax={5}
                variant="outlined"
                fullWidth={true}
                helperText={emessage.t}
                onChange={(e) => ch(mset(e.target.value))}
                id="white-input1"
              />
            </Grid>
          </Grid>
          <button type="submit" id="form-btn-s">
            Send Message
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
