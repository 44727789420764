import React, { Component } from "react";
import { DeleteTwoTone, EditTwoTone, FileAddOutlined } from "@ant-design/icons";
import { Table, Typography,  Popconfirm,Button, Modal, Form, Input, message } from "antd";
import db from "../../database/firebase";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      amodal: false,
      umodal: false,
      uf: false,
      af: false,
      pagination: {
        pageSize: 5,
      },
      loading: false,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Icon",
          dataIndex: "icon",
          align: "center",
          render: (i) => <i className={i}></i>,
        },
        {
          title: "Actions",
          align: "center",
          render: (i) => (
            <Button.Group shape="round">
              <Button
                type="dashed"
                onClick={() => {
                  this.setState({ umodal: true, uid: i.id }, () => {
                    this.uref.current.setFieldsValue(i);
                  });
                }}
                icon={<EditTwoTone twoToneColor="#52c41a" />}
                shape="round"
              />
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => this.delete(i.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="dashed"
                  icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                  shape="round"
                />
              </Popconfirm>
            </Button.Group>
          ),
        },
      ],
      data: [],
    };
    this.coll = "SERVICES";
    this.fref = React.createRef();
    this.uref = React.createRef();
  }

  delete = (id) => {
    this.setState({ loading: true }, () => {
      db.firestore()
        .collection(this.coll)
        .doc(id)
        .delete()
        .then(() => {
          message.success("Deleted Successfully.");
          this.fetch();
        })
        .catch((err) => {
          alert(err);
          this.setState({ loading: false });
        });
    });
  };

  fetch = () => {
    this.setState({ loading: true }, () => {
      db.firestore()
        .collection(this.coll)
        .get()
        .then((r) => {
          const all = r.docs.map((r) => {
            return { ...r.data(), id: r.id };
          });
          this.setState({ data: all }, () => this.setState({ loading: false }));
        });
    });
  };

  componentDidMount() {
    this.fetch();
  }

  close = (name) => {
    this.setState({ [name]: false });
  };

  open = (name) => {
    this.setState({ [name]: true });
  };

  add = (val) => {
    this.setState({ af: true }, () => {
      db.firestore()
        .collection(this.coll)
        .add(val)
        .then(() => {
          this.setState({ af: false, amodal: false });
          this.fref.current.resetFields();
          message.success("Record Added Successfully.");
          this.fetch();
        })
        .catch((err) => {
          alert(err);
        });
    });
  };

  update = (val) => {
    this.setState({ uf: true }, () => {
      db.firestore()
        .collection(this.coll)
        .doc(this.state.uid)
        .update(val)
        .then(() => {
          this.setState({ uf: false, umodal: false });
          this.uref.current.resetFields();
          message.success("Record Updated Successfully.");
          this.fetch();
        })
        .catch((err) => {
          alert(err);
        });
    });
  };

  render() {
    const { data, columns, loading, umodal, pagination, amodal } = this.state;
    return (
      <div>
        <Modal
          title="Add new record"
          visible={amodal}
          onCancel={() => {
            this.close("amodal");
          }}
          footer={false}
        >
          <Form
            ref={this.fref}
            name="basic"
            layout="vertical"
            loading={true}
            onFinish={this.add}
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="name"
              name="name"
              rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="icon"
              name="icon"
              rules={[{ required: true, message: "Please input icon!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.af}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Edit record"
          visible={umodal}
          onCancel={() => {
            this.close("umodal");
          }}
          footer={false}
        >
          <Form
            ref={this.uref}
            name="update"
            layout="vertical"
            loading={true}
            onFinish={this.update}
          >
            <Form.Item
              label="name"
              name="name"
              rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="icon"
              name="icon"
              rules={[{ required: true, message: "Please input icon!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.uf}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Typography.Title>Services</Typography.Title>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          onClick={() => this.open("amodal")}
          icon={<FileAddOutlined />}
        >
          New
        </Button>
        <Table
          loading={loading}
          columns={columns}
          pagination={pagination}
          dataSource={data}
        />
      </div>
    );
  }
}
