import React from "react";
import Grid from "@material-ui/core/Grid";
import "../Home/welcome.css";

export default function CenteredGrid() {
  return (
    <div
      id="welcome-slider"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1520085601670-ee14aa5fa3e8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container>
        <Grid item md={5} sm={4} xs={false}>
          <div
            id="welcome-left-shadow"
            style={{ backgroundColor: "#191C26" }}
          ></div>
        </Grid>
      </Grid>
      <div id="welcome-title" className="res-line">
        <div className="the-line"></div>
        <div className="welcome-techeye">TECH-EYE's PRODUCTS</div>
      </div>

      <div id="welcome-sol">
        <h2>Make It</h2>
        <h2>
          Use It<span className="span_dot"></span>{" "}
        </h2>
      </div>
      <div id="welcome-detail">
        We always produce what we want to use, so that our creative ideas can be
        functional and most efficient.
      </div>
    </div>
  );
}
