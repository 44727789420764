import React from "react";
import Top from "../components/Products/Top";
import Shopping from "../components/Products/Shopping";
import { useEffect } from "react";
export default function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Top />
      <Shopping />
    </>
  );
}
