import React, { Component } from "react";
import { EditTwoTone } from "@ant-design/icons";
import {
  Table,
  InputNumber,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  message,
} from "antd";
import db from "../../database/firebase";

export default class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      amodal: false,
      umodal: false,
      uf: false,
      af: false,
      pagination: {
        pageSize: 5,
      },
      loading: false,
      columns: [
        {
          title: "Title",
          dataIndex: "title",
        },
        {
          title: "Number",
          dataIndex: "number",
          align: "center",
        },
        {
          title: "Actions",
          align: "center",
          render: (i) => (
            <Button.Group shape="round">
              <Button
                type="dashed"
                onClick={() => {
                  this.setState({ umodal: true, uid: i.id }, () => {
                    this.uref.current.setFieldsValue(i);
                  });
                }}
                icon={<EditTwoTone twoToneColor="#52c41a" />}
                shape="round"
              />
            </Button.Group>
          ),
        },
      ],
      data: [],
    };
    this.coll = "clients";
    this.fref = React.createRef();
    this.uref = React.createRef();
  }

  fetch = () => {
    this.setState({ loading: true }, () => {
      db.firestore()
        .collection(this.coll)
        .get()
        .then((r) => {
          const all = r.docs.map((r) => {
            return { ...r.data(), id: r.id };
          });
          this.setState({ data: all }, () => this.setState({ loading: false }));
        });
    });
  };

  componentDidMount() {
    this.fetch();
  }

  close = (name) => {
    this.setState({ [name]: false });
  };

  open = (name) => {
    this.setState({ [name]: true });
  };

  update = (val) => {
    this.setState({ uf: true }, () => {
      db.firestore()
        .collection(this.coll)
        .doc(this.state.uid)
        .update(val)
        .then(() => {
          this.setState({ uf: false, umodal: false });
          this.uref.current.resetFields();
          message.success("Record Updated Successfully.");
          this.fetch();
        })
        .catch((err) => {
          alert(err);
        });
    });
  };

  render() {
    const { data, columns, loading, umodal, pagination } = this.state;
    return (
      <div>
        <Modal
          title="Edit record"
          visible={umodal}
          onCancel={() => {
            this.close("umodal");
          }}
          footer={false}
        >
          <Form
            ref={this.uref}
            name="update"
            layout="vertical"
            loading={true}
            onFinish={this.update}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Number"
              name="number"
              type="number"
              rules={[{ required: true, message: "Please input Number!" }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.uf}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Typography.Title>Works</Typography.Title>
        <Table
          loading={loading}
          columns={columns}
          pagination={pagination}
          dataSource={data}
        />
      </div>
    );
  }
}
