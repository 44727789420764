import React from "react";
import Top from "../components/SAll/Top";
import Shopping from "../components/SAll/Shopping";
import { useEffect } from "react";
export default function Shop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Top />
      <Shopping />
    </>
  );
}
