import Serv from "../components/ProductI/Services";
import Portfolio from "../components/Products/Shopping";
export default function Services() {
  return (
    <>
      <Serv />
      <Portfolio />
    </>
  );
}
