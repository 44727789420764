import React, { Component } from "react";
import { DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import { Table, Typography, Button, Popconfirm,Modal, message } from "antd";
import db from "../../database/firebase";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      amodal: false,
      umodal: false,
      uf: false,
      af: false,
      pagination: {
        pageSize: 5,
      },
      loading: false,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Email",
          dataIndex: "email",
        },
        {
          title: "Actions",
          align: "center",
          render: (i) => (
            <Button.Group shape="round">
              <Button
                type="dashed"
                onClick={() => {
                  this.setState({ umodal: true, uid: i.message }, () => {});
                }}
                icon={<EyeTwoTone twoToneColor="#52c41a" />}
                shape="round"
              />
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => this.delete(i.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="dashed"
                  icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                  shape="round"
                />
              </Popconfirm>
            </Button.Group>
          ),
        },
      ],
      data: [],
    };
    this.coll = "contact";
    this.fref = React.createRef();
    this.uref = React.createRef();
  }

  delete = (id) => {
    this.setState({ loading: true }, () => {
      db.firestore()
        .collection(this.coll)
        .doc(id)
        .delete()
        .then(() => {
          message.success("Deleted Successfully.");
          this.fetch();
        })
        .catch((err) => {
          alert(err);
          this.setState({ loading: false });
        });
    });
  };

  fetch = () => {
    this.setState({ loading: true }, () => {
      db.firestore()
        .collection(this.coll)
        .get()
        .then((r) => {
          const all = r.docs.map((r) => {
            return { ...r.data(), id: r.id };
          });
          this.setState({ data: all }, () => this.setState({ loading: false }));
        });
    });
  };

  componentDidMount() {
    this.fetch();
  }

  close = (name) => {
    this.setState({ [name]: false });
  };

  open = (name) => {
    this.setState({ [name]: true });
  };

  render() {
    const { data, columns, loading, umodal, pagination } = this.state;
    return (
      <div>
        <Modal
          title="Message"
          visible={umodal}
          onCancel={() => {
            this.close("umodal");
          }}
          footer={false}
        >
          {this.state.uid}
        </Modal>
        <Typography.Title>Contact</Typography.Title>
        <Table
          loading={loading}
          columns={columns}
          pagination={pagination}
          dataSource={data}
        />
      </div>
    );
  }
}
