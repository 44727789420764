import React from "react";
import Grid from "@material-ui/core/Grid";
import "../Home/welcome.css";

export default function CenteredGrid() {
  return (
    <div
      id="welcome-slider"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1532102235608-dc8fc689c9ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container>
        <Grid item md={5} sm={4} xs={false}>
          <div
            id="welcome-left-shadow"
            style={{ backgroundColor: "#191C26" }}
          ></div>
        </Grid>
      </Grid>
      <div id="welcome-title" className="res-line">
        <div className="the-line"></div>
        <div className="welcome-techeye">OUR SERVICES</div>
      </div>

      <div id="welcome-sol">
        <h2>You Ask</h2>
        <h2>
          We Build<span className="span_dot"></span>{" "}
        </h2>
      </div>
      <div id="welcome-detail">
        You may be amazed by what we can do. We offer many services.
      </div>
    </div>
  );
}
