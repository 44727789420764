import React from "react";
import Grid from "@material-ui/core/Grid";
import "./about.css";

export default function BasicTextFields() {
  const image =
    "https://bl6pap003files.storage.live.com/y4mXc-vWF8qn0F3NkXGLvitxHbi8Kir0gWDjAlOaA1_Ohhk4ENVy-9JwCZlucMmGu1-50YNFAG7zvIrp-jGp7OCa4F8cuLqyqPuzxCQmbdKPm_DldouSA8sxA5Y22MSlrhVsypS_wrEfAPqbh_F2e8xGBttqpipMVXzVw1jNNkOXK7pdKSQ_QuL60jxIJ_D6ViK?width=1785&height=1080&cropmode=none";
  return (
    <div
      id="about"
      className="sections"
      style={{
        height: "auto",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container>
        <Grid item md={5} />
        <Grid item md={5}>
          <div data-aos="fade-left" data-aos-duration="1500">
            <div className="the-line"></div>
            <div className="welcome-techeye" id="about-about">
              ABOUT US
            </div>
            <div id="about-title">
              <span>We Are Experts</span>
              <br />
              <span>
                In Our Field<span className="span_dot"></span>
              </span>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500">
            <div id="about-p1" className="contentakan">
              Under a unique banner, but a super connotation, which is
              “Technology is Our World,” Tech Eye started as one of the local
              leading projects in the Iraqi Kurdistan Region to fulfill and
              provide the technological needs and services.
            </div>
            <div id="about-p2" className="contentakan">
              What make us an outstanding company is a progressed and developed
              company in the field of Information Technology. We provide
              Services like Web Design, Data-base Networking, Web Development,
              Server, Web Host, and Digital Marketing, to name a few.
            </div>
          </div>
        </Grid>
        <Grid item md={2} />
      </Grid>
    </div>
  );
}
