import React, { useState, useEffect } from "react";
import "./portfolio.css";
import db from "../../database/firebase";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Footer from "../Footer";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

function yalla(el) {
  document.getElementById(el).style.opacity = "1";
}

function unyalla(el) {
  document.getElementById(el).style.opacity = "0";
}

export default function Portfolio() {
  const history = useHistory();
  const classes = useStyles();
  const [post, set] = useState([]);
  useEffect(() => {
    db.firestore()
      .collection("projects")
      .orderBy("name", "asc")
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data(), id: r.id };
        });
        set([...all]);
      });
  }, []);

  return (
    <div className="sections" style={{ background: "#121316" }}>
      <div className="the-line" />
      <div className="welcome-techeye" id="about-about">
        All Projects
      </div>
      <br />
      <div className="serv1-h">
        <span className="portfolio-head-t" id="services-desc">
          See The Best Projects Here
          <span className="span_dot"></span>
        </span>
      </div>
      <div>See Our Bests and give us Your opinion.</div>
      <Grid
        style={{ marginTop: 25 }}
        container
        className={classes.root}
        spacing={0}
      >
        <Grid item xs={12}>
          <Grid container justify="center">
            {post.map((el, i) => (
              <div key={el.name} className="project-el">
                <div>
                  <img alt={el.name} src={el.img} />
                  <div
                    onClick={() => {
                      history.push(`/projects/${el.id}`);
                    }}
                    onMouseEnter={() => yalla(`project-shadow-${i}`)}
                    onMouseLeave={() => unyalla(`project-shadow-${i}`)}
                    className="project-shadow"
                    id={`project-shadow-${i}`}
                  >
                    <span>
                      {el.name}
                      <br />
                      &gt;
                    </span>
                  </div>
                  <h3 style={{ margin: "0px 0px 5px 0px", color: "white" }}>
                    {el.name}
                  </h3>
                </div>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
