import React, { Component, useState } from "react";
import "./login.css";
import { Form, Input, Button, message } from "antd";
import tech from "../../assets/tech.svg";
import db from "../../database/firebase.js";
import { useHistory } from "react-router";

export default class Login extends Component {
  render() {
    return (
      <div id="new-d-login">
        <div id="for-style">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ width: 100, marginBottom: 20 }}
              alt="techeye"
              src={tech}
            />
          </div>
          <div>
            <Demo />
          </div>
        </div>
      </div>
    );
  }
}

const Demo = () => {
  const history = useHistory();
  const [loading, set] = useState(false);
  const onFinish = (v) => {
    set(true);
    db.auth()
      .signInWithEmailAndPassword(v.username, v.password)
      .then(() => {
        set(false);
        history.push("/dashboard/services");
      })
      .catch((error) => {
        set(false);
        message.error("This Account doas not exists.");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
