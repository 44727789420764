import React, { useContext } from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/Admin/Login";
import { AuthContext } from "../Auth.js";
import "./dashboard.css";
const DAshboard = () => {
  const { user } = useContext(AuthContext);

  return (
    <div id="dashboard-dd">
      {!!user ? <Dashboard /> : <Login />}
    </div>
  );
};

export default DAshboard;
