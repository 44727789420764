import React, { useEffect, useState } from "react";
import "./clients.css";
import db from "../../database/firebase";
import Grid from "@material-ui/core/Grid";
import back from "../../assets/choose-us.svg";

export default function Clients() {
  const [all, set] = useState([]);
  useEffect(() => {
    db.firestore()
      .collection("support")
      .orderBy("name", "asc")
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data() };
        });
        set(all);
      });
  }, []);
  return (
    <div
      className="sections"
      id="clients"
      style={{ backgroundImage: `url(${back})` }}
    >
      <Grid container>
        <Grid item md={4} />
        <Grid item md={8}>
          <div className="the-line" />
          <div className="welcome-techeye" id="about-about">
            NETWORK
          </div>
          <br />
          <span id="services-desc">
            Our Clients<span className="span_dot"></span>
          </span>
          <div className="clients-tt-p tt-desc" style={{ width: 450 }}>
            These are people who trust us and will be hiring us whenever they
            have a project that needs to be worked on.
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: 100,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {all.map((el) => (
          <div
            key={el.name}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              margin: 25,
            }}
          >
            <img src={el.name} alt={el.name} style={{ height: 100 }} />
          </div>
        ))}
      </div>
    </div>
  );
}
