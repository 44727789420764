import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import db from "../../database/firebase";
import "./welcome.css";

export default function CenteredGrid() {
  const [len, lset] = useState(1);
  const [images, set] = useState([
    {
      img: "",
    },
  ]);
  const [pos, pset] = useState(0);
  useEffect(() => {
    db.firestore()
      .collection("sliders")
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data() };
        });
        set(all);
        lset(all.length);
      });
  }, []);

  return (
    <div
      id="welcome-slider"
      style={{
        backgroundImage: `url(${images[Math.abs(pos) % len].img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        transition: "1s",
      }}
    >
      <Grid container>
        <Grid item md={4} sm={false}>
          <div
            id="welcome-left-shadow"
            style={{ backgroundColor: "#191C26" }}
          ></div>
        </Grid>
      </Grid>
      <div id="welcome-title" className="res-line">
        <div className="the-line"></div>
        <div className="welcome-techeye">WELCOME TO TECH-EYE</div>
      </div>

      <div id="welcome-sol">
        <h2>Technology </h2>
        <h2>
          Solutions<span className="span_dot"></span>{" "}
        </h2>
      </div>
      <div id="welcome-detail" className="contentakan">
        We help amazing companies build awesome products, support to all
        customers who trust us and walk with us on this path.
      </div>
      <div id="welcome-slider-btn" className="no_select">
        <div className="span_main_prev forNextPrevHover">
          <span
            className="welcome-prev"
            onClick={() => {
              pset(pos - 1);
            }}
          >
            <span className="span_prev_p">PREV</span>
          </span>
        </div>
        <div className="span_main_next forNextPrevHover">
          <span
            className="welcome-next"
            onClick={() => {
              pset(pos + 1);
            }}
          >
            <span className="span_next_p">NEXT</span>
          </span>
        </div>
      </div>
    </div>
  );
}
