import "./services.css";
import React, { Component } from "react";
import background from "../../assets/slider_tech.svg";
import db from "../../database/firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  componentDidMount() {
    db.firestore()
      .collection("SERVICES")
      .orderBy("name", "asc")
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data() };
        });
        this.setState({ services: all });
      });
  }

  render() {
    var setting = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      speed: 500,
      centerMode: true,
      swipeToSlide: true,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div
        className="sections"
        id="services"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
        }}
      >
        <div id="Services">
          <div data-aos="fade-right" data-aos-duration="1500">
            <div className="the-line"></div>
            <div className="welcome-techeye" id="about-about">
              OUR SERVICES
            </div>
            <br />
            <span id="services-desc">
              We Do Everything<span className="span_dot"></span>
            </span>
            <div id="services-con" className="contentakan">
              You may be amazed by what we can do. We offer many services which
              you can find below.
            </div>
          </div>
        </div>

        <div
          data-aos="fade"
          data-aos-duration="1500"
          className="seeAll no_select"
        >
          <div className="span_main_prev forNextPrevHover">
            <span onClick={this.previous} className="welcome-prev">
              <span className="span_prev_p">PREV</span>
            </span>
          </div>
          <div className="span_main_next forNextPrevHover">
            <span onClick={this.next} className="welcome-next">
              <span className="span_next_p">NEXT</span>
            </span>
          </div>
        </div>
        <Slider
          ref={(c) => (this.slider = c)}
          className="services-slider-con"
          arrows={false}
          {...setting}
        >
          {this.state.services.map((el, i) => (
            <div data-aos="fade" data-aos-duration="1500" key={el.name}>
              <div
                className="services-card yallaborder draw meet"
                style={{ background: i % 2 === 0 ? "#222633" : "#15171f" }}
              >
                <div className="services-slider_banner">
                  <i
                    className={el.icon}
                    style={{ fontSize: 35, color: "white" }}
                  />
                </div>
                <div className="services-card-name">{el.name}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
