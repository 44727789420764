import "./Nav.css";
import open from "../assets/nav.svg";
import close from "../assets/Vector.svg";
import tech from "../assets/tech.svg";
import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useHistory } from "react-router";

const routes = [
  { path: "/", component: "Home", exact: true, element: "welcome-slider" },
  {
    path: "/about-us",
    component: "About",
    exact: false,
    element: "about",
  },
  {
    path: "/our-services",
    component: "Our Services",
    exact: false,
    element: "services",
  },
  {
    path: "/choose-us",
    component: "Choose us",
    exact: false,
    element: "choose",
  },
  {
    path: "/projects",
    component: "Portfolio",
    exact: false,
    element: "projects",
  },
  { path: "/clients", component: "Clients", exact: false, element: "clients" },
  // {
  //   path: "/products",
  //   component: "Products",
  //   exact: false,
  //   element: "",
  // },
  {
    path: "/services-detail",
    component: "Services",
    exact: false,
    element: "services",
  },
];

export default function Nav() {
  const history = useHistory();
  const [classes, cset] = useState("");

  const change = () => {
    classes === "" ? cset("open") : cset("");
  };

  const closing = () => {
    cset("");
  };
  return (
    <div id="Nav" className={classes}>
      <div>
        <div
          alt="nav"
          className="klinehoverfull"
          id="nav-svg"
          src={classes === "" ? open : close}
          onClick={change}
        >
          <div
            id="firstLine"
            className={`${classes !== "" ? "kfullopen" : ""}`}
          />
          <div
            id="secondLine"
            className={`${classes !== "" ? "kfullopen" : ""}`}
          />
          <div
            id="thirdLine"
            className={`${classes !== "" ? "kfullopen" : ""}`}
          />
        </div>
        {/*<NavLink id="nav-deg-1" to="/shop" exact onClick={closing}>*/}
        {/*  Shop*/}
        {/*</NavLink>*/}
        <NavLink
          id="nav-deg-2"
          to="/projects"
          exact
          onClick={() => {
            const el = document.getElementById("projects");
            if (el) window.scrollTo(0, el.offsetTop);
            closing();
          }}
        >
          Projects
        </NavLink>
      </div>
      <div
        style={{ display: classes === "" ? "none" : "block", transition: "1s" }}
      >
        <img
          alt="techeye"
          onClick={() => {
            window.scrollTo(0, 0);
            if (window.location.pathname) {
              history.push("/");
            }
            change();
          }}
          className="hover no_select"
          src={tech}
          id="nav-banner"
        />
        <ul>
          {routes.map((el) => (
            <NavLink
              onClick={() => {
                change();
                const ell = document.getElementById(el.element);
                if (ell) window.scrollTo(0, ell.offsetTop);
              }}
              to={el.path}
              exact={el.exact}
            >
              <li key={el.path}>
                  {el.component}
              </li>
            </NavLink>
          ))}
        </ul>
        <button id="nav-quote">
          <Link
            onClick={() => {
              change();
              window.scrollTo(0, document.getElementById("form").offsetTop);
            }}
            style={{ color: "white" }}
            to="/contact"
          >
            Get a Quote
          </Link>
        </button>
      </div>
      {/*<div*/}
      {/*  id="nav-place"*/}
      {/*  style={{ display: classes === "" ? "none" : "block" }}*/}
      {/*>*/}
      {/*  <div className="nav-bottom-dir">*/}
      {/*    Erbil, Italian village 1, villa no. 103*/}
      {/*  </div>*/}
      {/*  <a className="nav-bottom-dir" href="tel:+9647504515480">*/}
      {/*    +964 750 451 5480*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
}
