import React, { useEffect, useState } from "react";
import "./clients.css";
import db from "../../database/firebase";
import Grid from "@material-ui/core/Grid";
import back from "../../assets/choose-us.svg";
import { Link } from "react-router-dom";
import arrow from "../../assets/arrow.svg";

export default function Clients() {
  const [all, set] = useState([]);
  useEffect(() => {
    db.firestore()
      .collection("support")
      .orderBy("name", "asc")
      .limit(8)
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data() };
        });
        set(all);
      });
  }, []);
  return (
    <div
      className="sections"
      id="clients"
      style={{ backgroundImage: `url(${back})` }}
    >
      <Grid container>
        <Grid item md={4} />
        <Grid item md={8}>
          <div data-aos="fade-right" data-aos-duration="1500">
            <div className="the-line" />
            <div className="welcome-techeye" id="about-about">
              NETWORK
            </div>
            <br />
            <span id="services-desc">
              Our Clients<span className="span_dot"></span>
            </span>
            <div
              className="clients-tt-p tt-desc contentakan"
              style={{ width: 450 }}
            >
              These are people who trust us and will be hiring us whenever they
              have a project that needs to be worked on.
            </div>
          </div>
        </Grid>
      </Grid>
      <div
        data-aos="fade"
        data-aos-duration="1500"
        className="seeAll no_select"
      >
        <Link to="/clients-all">
          SEE ALL CLIENTS <img alt="arrow" src={arrow} />
        </Link>
      </div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 30 }}
      >
        {all.map((el, i) => (
          <Grid item xs={6} md={4} lg={3}>
            <div
              data-aos="fade"
              data-aos-duration="1500"
              key={el.name}
              style={{ textAlign: "center", margin: "25px 0px" }}
            >
              <img src={el.name} alt={el.name} style={{ width: 100 }} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
