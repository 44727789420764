import React, { useEffect, useState } from "react";
import "./services.css";
import { useParams } from "react-router-dom";
import db from "../../database/firebase.js";
import Footer from "../Footer";
export default function Services() {
  let { id } = useParams();
  const [p, set] = useState({});
  useEffect(() => {
    db.firestore()
      .collection("projects")
      .doc(id)
      .get()
      .then((r) => {
        set(r.data());
      });
  });

  return (
    <div style={{ paddingTop: 120 }} className="sections">
      <div className="the-line" />
      <div className="welcome-techeye" id="about-about">
        OUR PROJECTS
      </div>
      <br />
      <div className="serv1-h">
        <span className="portfolio-head-t" id="services-desc">
          {p.name}
          <span className="span_dot"></span>
        </span>
      </div>
      <img src={p.img} alt="mobile" className="serv1-mobile" />

      <div className="serv1-detail">{p.caption}</div>
      <Footer />
    </div>
  );
}
