import React, { useEffect, useState } from "react";
import app from "./database/firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, set] = useState(null);
  useEffect(() => {
    app.auth().onAuthStateChanged(set);
  }, []);
  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
