import React from "react";
import logo from "./../assets/tech.svg";
import Grid from "@material-ui/core/Grid";
import "./Home/form.css";
export default function Footer() {
  return (
    <div>
      <hr />
      <Grid container align="center">
        <Grid item xs={12} md={3} sm={6}>
            <img alt="logo" src={logo} style={{ width: "100px" }} />
            <br />
            <br />
            <span
              id="form-copy-right-k"
              style={{ color: "white", textShadow: "none" }}
            >
              Copyright © 2021 Tech-Eye.
            </span>
            <br />
            <br />
        </Grid>
        <Grid item className="form_shadow_text" xs={12} md={3} sm={6}>
            <span style={{ color: "white" }}>Working hours:</span>
            <p className="kcolor">
                Sat -Thu : 9 am - 5 pm
              <br />
                Fri: Holiday
            </p>
        </Grid>
        <Grid item className="form_shadow_text" xs={12} md={3} sm={6}>
            <span style={{ color: "white" }}>Address:</span>
            <p className="kcolor">
              Head Quarter: Italian city  1, villa 103 , Erbil, Iraq,
              <br />
              Showroom: 100m Road, Next to Ala High School, Erbil, Iraq
            </p>
        </Grid>
        <Grid item className="form_shadow_text" xs={12} md={3} sm={6}>
          <span style={{ color: "white" }}>Hit us up:</span>
          <p className="kcolor">
            +964 750 451 5480
            <br />
            info@tech-eye.com
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
