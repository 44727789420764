import React from "react";
import Grid from "@material-ui/core/Grid";
import "../Home/welcome.css";

export default function CenteredGrid() {
  return (
    <div
      id="welcome-slider"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1620325867502-221cfb5faa5f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1039&q=80)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container>
        <Grid item md={5} sm={4} xs={false}>
          <div
            id="welcome-left-shadow"
            style={{ backgroundColor: "#191C26" }}
          ></div>
        </Grid>
      </Grid>
      <div id="welcome-title" className="res-line">
        <div className="the-line"></div>
        <div className="welcome-techeye">TECH-EYE's PROJECTS</div>
      </div>

      <div id="welcome-sol">
        <h2>See Our</h2>
        <h2>
          Projects<span className="span_dot"></span>{" "}
        </h2>
      </div>
      <div id="welcome-detail">
        Every ones will have great ideas but we are the only ones who try to
        make them real.
      </div>
    </div>
  );
}
