import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Auth.js";
import Dashboard from "./containers/Dashboard";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" component={App} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("techeye")
);

reportWebVitals();
