import {
  CheckCircleOutlined,
  UserOutlined,
  MailOutlined,
  AppstoreAddOutlined,
  HomeOutlined,
  SlidersOutlined,
  ShopOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import Home from "./Home";
import Services from "./Services";
import Clients from "./Clients";
import Contact from "./Contact";
import Projects from "./Projects";
import Sliders from "./Sliders";
import Support from "./Support";
import Products from "./Products";
// import Store from "./Store";
const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    component: Home,
    logo: <HomeOutlined />,
  },
  {
    path: "/dashboard/services",
    exact: false,
    name: "Services",
    component: Services,
    logo: <CheckCircleOutlined />,
  },
  {
    path: "/dashboard/clients",
    exact: false,
    name: "Clients",
    component: Clients,
    logo: <UserOutlined />,
  },
  {
    path: "/dashboard/contact",
    exact: false,
    name: "Contact",
    component: Contact,
    logo: <MailOutlined />,
  },
  {
    path: "/dashboard/projects",
    exact: false,
    name: "Projects",
    component: Projects,
    logo: <AppstoreAddOutlined />,
  },
  {
    path: "/dashboard/sliders",
    exact: false,
    name: "Sliders",
    component: Sliders,
    logo: <SlidersOutlined />,
  },
  {
    path: "/dashboard/support",
    exact: false,
    name: "Support",
    component: Support,
    logo: <SwapOutlined />,
  },
  // {
  //   path: "/dashboard/store",
  //   exact: false,
  //   name: "Store",
  //   component: Store,
  //   logo: <ShopOutlined />,
  // },
  {
    path: "/dashboard/products",
    exact: true,
    name: "Products",
    component: Products,
    logo: <ShopOutlined />,
  },
];

export default routes;
