import Home from "./containers/Home";
import Products from "./containers/Products";
import ProductI from "./containers/ProductI";
import Nav from "./containers/Nav";
import Nav2 from "./components/Home/Nav";
import Shop from "./containers/Shop";
import ProjectI from "./containers/ProjectI";
import ClientsA from "./containers/Clients";
import ProjectAll from "./containers/Project";
import { Route, Switch } from "react-router-dom";
import "./index.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const routes = [
  { path: "/products", component: Products, exact: true },
  { path: "/product/:id", component: ProductI, exact: true },
  { path: "/projects/:id", component: ProjectI, exact: true },
  { path: "/clients-all", component: ClientsA, exact: true },
  { path: "/projects-all", component: ProjectAll, exact: true },
  { path: "/shop", component: Shop, exact: true },
  { path: "/", component: Home, exact: false },
];

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div id="webapp-normal">
      <Nav />
      <div id="AllContent">
        <Nav2 />
        <Switch>
          {routes.map((el) => (
            <Route
              key={el.path}
              exact={el.exact}
              path={el.path}
              component={el.component}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}

export default App;
