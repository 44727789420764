import Welcome from "../components/Home/Welcome";
import About from "../components/Home/About";
import Services from "../components/Home/Services";
import Choose from "../components/Home/Choose";
import Portfolio from "../components/Home/Portfolio";
import Social from "../components/Home/Social";
import Clients from "../components/Home/Clients";
import Form from "../components/Home/Form";
import { useEffect } from "react";

function yalla(path) {
  switch (path) {
    case "/":
      return "";
    case "/clients":
      return "clients";
    case "/projects":
      return "projects";
    case "/services-detail":
      return "services";
    case "/contact":
      return "form";
    default:
      return "";
  }
}

export default function Home() {
  useEffect(() => {
    const gg = yalla(window.location.pathname);
    if (gg && gg !== "") {
      setTimeout(() => {
        const el = document.getElementById(gg);
        window.scrollTo(0, el.offsetTop);
      }, 500);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <Welcome />
      <About />
      <Services />
      <Choose />
      <Portfolio />
      <Social />
      <Clients />
      <Form />
    </>
  );
}
