import React, { useState, useEffect } from "react";
import "../Home/clients.css";
import Grid from "@material-ui/core/Grid";
import Footer from "../Footer";
import db from "../../database/firebase";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function Shopping() {
  const classes = useStyles();
  const [post, set] = useState([]);
  useEffect(() => {
    db.firestore()
      .collection("products")
      .get()
      .then((r) => {
        const all = r.docs.map((r) => {
          return { ...r.data(), id: r.id };
        });
        set([...all]);
      });
  }, []);
  return (
    <div className="sections" style={{ background: "#121316" }}>
      <Grid container>
        <Grid item md={4} />
        <Grid item md={8}>
          <div className="the-line" />
          <div className="welcome-techeye" id="about-about">
            OUR PRODUCTS
          </div>
          <br />
          <span id="services-desc">
            We Build The Best<span className="span_dot"></span>
          </span>
          <div className="clients-tt-p tt-desc" style={{ width: 450 }}>
            Our products are high level and built by amazing people.
          </div>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: 25 }}
        container
        className={classes.root}
        spacing={0}
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={0}>
            {post.map((el, i) => (
              <div
                key={el.name}
                style={{
                  margin: 20,
                  width: 200,
                  height: 275,
                  background: "#222633",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: 200,
                    height: 200,
                    backgroundImage: `url(${el.image})`,
                    backgroundSize: "cover",
                  }}
                ></div>
                <h3 style={{ color: "white", padding: 10 }}>{el.name}</h3>
                <Link
                  to={`/product/${el.id}`}
                  style={{
                    position: "absolute",
                    bottom: 10,
                    right: 10,
                    paddingBottom: 1,
                    borderBottom: "2px solid",
                  }}
                >
                  CHECK OUT
                </Link>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
